import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import {format} from 'date-fns'
import PortableText from './portableText'
import Container from './container'
import '../styles/presentation-single.css'
import {Link} from 'gatsby'
import {getPresentationUrl} from '../lib/helpers'
import ReactPlayer from 'react-player'

function PresentationSingle (props) {
  const {image, pdf, title, publishedAt, webLink, slug, video, _rawDescription} = props
  //console.log(pdf)

  return (
    <Container>
      <div className="wrapper">
        <h3><Link to="/presentations/">Presentations</Link></h3>
        <div id="presentation-single" className="presentation-single grid">

          <div className="width-12-12-m single">

            <h2>{title}</h2>

            <div className="date-tag-holder">
              <div class="date-holder">
                <span className="date">
                  {format(new Date(publishedAt), 'MMMM Do, YYYY')}
                </span>
              </div>
            </div>

              {image && image.desktopImage && image.desktopImage.asset && (
                <div className="image-holder">
                  <Link to={getPresentationUrl(publishedAt, slug.current)}>
                  <picture>
                    <source 
                      media="(max-width: 699px)" 
                      srcset={imageUrlFor(buildImageObj(image.mobileImage))
                        .width(1000)
                        .height(Math.floor((1 / 1) * 1000))
                        .auto('format')
                        .url()}
                    />
                    <source 
                      media="(min-width: 700px)" 
                      srcset={imageUrlFor(buildImageObj(image.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                    />
                    <img
                      src={imageUrlFor(buildImageObj(image.desktopImage))
                        .width(2000)
                        .height(Math.floor((1 / 2) * 2000))
                        .auto('format')
                        .url()}
                      alt={image.desktopImage.alt}
                      title={image.desktopImage.alt}
                    />
                  </picture>
                  </Link>
                  {image.desktopImage.caption && (
                    <span className="caption">
                      {image.desktopImage.caption}
                    </span>
                  )}

                </div>
              )}

              {_rawDescription && (
                  <div className="body-holder">
                    {_rawDescription && <PortableText blocks={_rawDescription} />}

                    {video && video.url && (
                      <div className="video-holder">
                        <div className='player-wrapper'>
                          <ReactPlayer 
                            className='react-player'
                            url={video.url}
                            width='100%'
                            height='100%'
                          />
                        </div>
                      </div>
                    )}

                    {pdf && pdf.file && 
                        <p><a href={pdf.file.asset.url} rel="noopener noreferrer" target="_blank">{pdf.title} (pdf)</a></p>
                    }

                    {webLink && 
                        <p className="extras"><a href={webLink} rel="noopener noreferrer" target="_blank">View Presentation</a></p>
                    }

                  </div>
                )}

              </div>


          </div>

        </div>

    </Container>
  )
}

export default PresentationSingle