import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import PresentationSingle from '../components/presentation-single'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents
} from '../lib/helpers'

export const query = graphql`
  query PresentationTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    presentation: sanityPresentation(id: {eq: $id}) {
      id
      publishedAt
      image {
        desktopImage {
          ...SanityImage
          alt
          caption
        }
        mobileImage {
          ...SanityImage
          alt
          caption
        }
      }
      pdf {
        title
        file {
          asset {
            url
          }
        }
      }
      webLink
      video {
        url
      }
      title
      slug {
        current
      }
      _rawDescription(resolveReferences: {maxDepth: 5})
    }
  }
`

const PresentationTemplate = props => {
  const {data, errors} = props

  const site = (data || {}).site

  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const presentation = data && data.presentation

  let pimage = '';

  if(presentation.image) {
    pimage = presentation.image.desktopImage;
  }

  //console.log('presentation');
  //console.log(presentation);

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink}>
      {errors && <SEO title='GraphQL Error' />}
      {presentation && <SEO title={presentation.title || 'Untitled'} author={site.author} description={toPlainText(presentation._rawDescription)} image={pimage} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {presentation && 
        <PresentationSingle {...presentation} />
      }
    </Layout>
  )
}

export default PresentationTemplate